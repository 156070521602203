import { useHead, useRuntimeConfig } from 'nuxt/app'

export default function useTitle (tit) {
  const title = `${useRuntimeConfig().public.name} | ${tit}`

  useHead({
    title,
    meta: [{ hid: 'og:title', name: 'og:title', content: title }],
  })
}
